@import url("../assets/css/styles.css"); /* Using a url */

:root {
  --black: #2E2909;
  --dark: #544A08;
  --text: #342D00;
  --yell: #FEDD00;
  --yellBright: #ffff01;
}

body {
  background-color: var(--yell);
  color: var(--dark);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
  text-decoration: none;
  transition-property: color, background, text-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
