@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Radley:ital@0;1&display=swap');


.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
/* Navbar */
.navbar {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.2em;
}
.navbar .logo {
    margin: 0 0 12px;
    height: 22px;
    padding: 0;
}

.links, .contact-links {
    font-family: 'Arimo';
    font-size: 13px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
}
.links a {
    color: var(--dark);
    font-weight: bold;
    margin: 0 12px;
    padding: 0 0 2px 0;
}
.links .active {
    color: #000;
}
.links a:hover {
    border-bottom: 3px solid var(--yellBright);
    color: #000;
}
/* Page */
.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: 'Lora';
    margin: 0;
}
/* Home */ 
.home {
    margin: 10vw 8vw 6vw;
    max-width: 1800px;
}
.home-block {
    background-color: var(--dark);
    color: var(--yell);
    display: flex;
    font-family: 'Arimo', sans-serif;
    font-size: 14px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    letter-spacing: 0.2px;
    padding: 9.6px 19.2px;
}
.page-title {
    font-weight: bold;
}
.contact-bar {
    display: flex;
    column-gap: 24px;
}
.contact-bar-number, .contact-bar-email {
    display: none;
}
.statement-container {
    background-color: var(--yellBright);
    display: flex;
    justify-content: center;
    width: 100%;
}
.statement-container a {
    color: var(--dark);
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
}
.statement-container a:hover {
    color: #000;
    text-decoration: none;
}
.page-statement {
    display: flex;
    column-gap: 8px;
    justify-content: center;
    padding: 32px 8vw;
    width: 100%;
}
.statement-home {
    margin: 0 32px 0 0;
}
.statement-block img {
    height: 22px;
}
#block1 {
    font-family: 'Radley', serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.02em;
    line-height: 1.35em;
    margin: 0 0 8px;
}
#block2 {
    font-size: 16px;
    font-family: 'Radley', serif;
    font-weight: normal;
    letter-spacing: -0.04em;
}
#block2 p {
    margin: 0 0 4px;
}
.page-statement .avatar img {
    border-radius: 360px;;
    max-width: 80px;
}
.home-block h2 {
    color: var(--yell);
    font-family: 'Arimo';
    font-size: 24px;
    margin: 16px 32px 32px;
}
.home h1 {
    background-color: var(--yell);
    font-family: 'Radley', serif;
    font-size: 18vw;
    font-weight: normal;
    letter-spacing: -0.06em;
    line-height: 0.8;
    margin: 0 0 64px;
}
/* .copy-sub-head {
    font-family: 'Arimo', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.6px;
    line-height: 24px;
} */
.home .copy-para p {
    font-size: 18px;
    font-family: 'Lora', serif;
    font-weight: normal;
    letter-spacing: -0.02em;
    line-height: 160%;
    margin: 0 0 1.2rem;
}
.home .col-icon {
    margin: 0 0 12px 0;
}
.home .content {
    display: flex;
    flex-direction: column;
}

/* Footer */
.footer {
    border-top: 1px solid rgba(0,0,0,0.2);
    font-size: 14px;
    padding: 40px 32px 16px;
    text-align: right;
    text-transform: uppercase;
}
.contact-links a {
    color: var(--dark);
    font-weight: bold;
    margin: 0 12px;
    padding: 0 0 2px 0;
}
.contact-links a:hover {
    border-bottom: 3px solid var(--yellBright);
    color: #000;
}
.copyright {
    margin: 12px;
}


/* X-Small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */
/* 
Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .navbar {
        flex-direction: row;
    }
    .navbar .logo {
        margin: 0 12px;
    }
    .home-block {
        padding: 9.6px 32px;
    }
    .contact-bar-number, .contact-bar-email {
        display: block;
    }
    .home {
        margin: 10vw auto 6vw;
        max-width: 1024px;
        padding: 0 64px;
    }
    .home .col-icon {
        margin: 4px 24px 0 0;
    }
    .home .content {
        flex-direction: row;    
    }
    .home .copy-para p {
        font-size: 22px;
    }
    .page-statement {
        align-items: center;
        justify-content: space-between;
        width: 1024px;
    }
    .page-statement .avatar img {
        border-radius: 360px;
        max-width: 180px;
    }
    #block1 {
        font-size: 32px;
        letter-spacing: -0.04em;
    }
    #block2 {
        font-size: 24px;
    }
    #block2 p {
        margin: 0 0 8px;
    }
    .statement-block img {
        height: 40px;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .home h1 {
        font-size: 160px;
    }
    
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .home {
        margin: 6vw auto;
        max-width: 1024px;
    }
    .home h1 {
        font-size: 176px;
    }
}